import {editionsByCode} from "~/config";
import {integer} from "vscode-languageserver-types";

export const submitAnswers = async () => {
    const emptyAnswer = (type: string) => {
        const editionCode = useUser()?.edition;
        const edition = editionCode && editionsByCode[editionCode];
        return type === 'MC1' ? (edition ? edition.emptyAnswer : 'X') : null;
    };

    const user = useUser();
    const storage = user && localStorage.getItem(user?.appParticipantsNumber);
    if (!storage) {
        return null;
    }
    const questions = await apiQuestions(useUser()?.edition);

    const answers = JSON.parse(storage);

    const normalizedAnswers = questions.map((question: {type: string, number: integer, answer: string | null | undefined}, index: integer) => {
        question.answer = answers[index] || emptyAnswer(question.type);

        return question;
    });

    //For some reason this calls the api twice, https://github.com/nuxt/nuxt/issues/13369
    return useFetch(`${window.location.origin}/api/answers`, {
        headers: {
            authorization: useAuth().token.value,
        },
        method: 'post',
        body: {
            answers: normalizedAnswers,
            phase: user?.phase
        }
    })
        .then(async ({data}) => {
            localStorage.clear();
            return data.value;
        })
        .catch((e) => {
            useFetch(`${window.location.origin}/api/log`, {
                method: 'post',
                body: {
                    tags: ['answers', 'submit', 'error', 'client'],
                    answers: normalizedAnswers,
                    message: `Submit error: ${e.message}`
                },
            });
        });
}
