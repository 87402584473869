export const apiQuestions = async (edition: string) => {
    const localStorageQuestions = getWithExpiry('questions-' + edition);

    if (localStorageQuestions && localStorageQuestions.length) {
        return localStorageQuestions;
    } else {
        return await useFetch(`${window.location.origin}/api/answers/get-questions-for-edition`, {
            headers: {
                authorization: useAuth().token.value,
            },
            method: 'post',
            body: {
                edition
            }
        })
            .then(({data}: {data: any}) => {
                setWithExpiry('questions-' + edition, data.value, 2 * 60 * 60 * 1000)
                return data.value;
            })
            .catch((e) => {
                console.log('Something went wrong' + e);
            });
    }
}

function setWithExpiry(key: string, value: any, ttl: number) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

function getWithExpiry(key: string) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}
