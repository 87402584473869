import lodash from "lodash";

export const answerOptions = ["A", "B", "C", "D", "E"];
export const emptyAnswer = "X";
export const kangoeroeEditionNames = {
    KANK: "Koala",
    KANS: "Springmuis",
    KANWR: "Wallaroe",
    KANWB: "Wallabie",
    KANWOM: "Wombat",
    KANQ: "Quokka",
    KANNUM: "Numbat"
};

// mock vwo-db
export const editions = [
    {
        name: "wombat",
        code: "KANWOM",
        numberOfQuestions: 15,
        answerOptions,
        emptyAnswer,
        parentEdition: "kangoeroe"
    },
    {
        name: "koala",
        code: "KANK",
        numberOfQuestions: 24,
        answerOptions,
        emptyAnswer,
        parentEdition: "kangoeroe"
    },
    {
        name: "wallabie",
        code: "KANWB",
        numberOfQuestions: 24,
        answerOptions,
        emptyAnswer,
        parentEdition: "kangoeroe"
    },
    {
        name: "springmuis",
        code: "KANS",
        numberOfQuestions: 24,
        answerOptions,
        emptyAnswer,
        parentEdition: "kangoeroe"
    },
    {
        name: "wallaroe",
        code: "KANWR",
        numberOfQuestions: 24,
        answerOptions,
        emptyAnswer,
        parentEdition: "kangoeroe"
    },
    {
        name: "quokka",
        code: "KANQ",
        numberOfQuestions: 24,
        answerOptions,
        emptyAnswer,
        parentEdition: "kangoeroe"
    },
    {
        name: "numbat",
        code: "KANNUM",
        numberOfQuestions: 24,
        answerOptions,
        emptyAnswer,
        parentEdition: "kangoeroe"
    }
];

const { keyBy } = lodash;
export const editionsByCode = keyBy(editions, "code");
export const getEditionScoring = (edition: string): { correct: number, wrong: number, blanc: number } => {
    switch (edition) {
        case "KANWOM":
        case "KANNUM":
        case "KANQ":
            return { correct: 8, wrong: 0, blanc: 2 };
        default:
            return { correct: 5, wrong: 0, blanc: 1 };
    }
};

export const passwords = (): {
    [date: string]: {
        [pwd: string]: {
            startDate: string;
            endDate: string;
        };
    };
} => ({
    "2024-03-21": {
        "ton": {
            "startDate": "2024-03-21T08:00:00",
            "endDate": "2024-03-21T09:00:00"
        },
        "sok": {
            "startDate": "2024-03-21T09:00:00",
            "endDate": "2024-03-21T10:00:00"
        },
        "sms": {
            "startDate": "2024-03-21T10:00:00",
            "endDate": "2024-03-21T11:00:00"
        },
        "lat": {
            "startDate": "2024-03-21T11:00:00",
            "endDate": "2024-03-21T12:00:00"
        },
        "fan": {
            "startDate": "2024-03-21T12:00:00",
            "endDate": "2024-03-21T13:00:00"
        },
        "fee": {
            "startDate": "2024-03-21T13:00:00",
            "endDate": "2024-03-21T14:00:00"
        },
        "oog": {
            "startDate": "2024-03-21T14:00:00",
            "endDate": "2024-03-21T15:00:00"
        },
        "koe": {
            "startDate": "2024-03-21T15:00:00",
            "endDate": "2024-03-21T16:00:00"
        },
        "ijs": {
            "startDate": "2024-03-21T16:00:00",
            "endDate": "2024-03-21T17:00:00"
        }
    },
    "2024-03-22": {
        "juf": {
            "startDate": "2024-03-22T08:00:00",
            "endDate": "2024-03-22T09:00:00"
        },
        "gsm": {
            "startDate": "2024-03-22T09:00:00",
            "endDate": "2024-03-22T10:00:00"
        },
        "gom": {
            "startDate": "2024-03-22T10:00:00",
            "endDate": "2024-03-22T11:00:00"
        },
        "lol": {
            "startDate": "2024-03-22T11:00:00",
            "endDate": "2024-03-22T12:00:00"
        },
        "mop": {
            "startDate": "2024-03-22T12:00:00",
            "endDate": "2024-03-22T13:00:00"
        },
        "dag": {
            "startDate": "2024-03-22T13:00:00",
            "endDate": "2024-03-22T14:00:00"
        },
        "opa": {
            "startDate": "2024-03-22T14:00:00",
            "endDate": "2024-03-22T15:00:00"
        },
        "mos": {
            "startDate": "2024-03-22T15:00:00",
            "endDate": "2024-03-22T16:00:00"
        },
        "pop": {
            "startDate": "2024-03-22T16:00:00",
            "endDate": "2024-03-22T17:00:00"
        }
    },
    "2024-03-25": {
        "uur": {
            "startDate": "2024-03-25T08:00:00",
            "endDate": "2024-03-25T09:00:00"
        },
        "zin": {
            "startDate": "2024-03-25T09:00:00",
            "endDate": "2024-03-25T10:00:00"
        },
        "pen": {
            "startDate": "2024-03-25T10:00:00",
            "endDate": "2024-03-25T11:00:00"
        },
        "vlo": {
            "startDate": "2024-03-25T11:00:00",
            "endDate": "2024-03-25T12:00:00"
        },
        "bal": {
            "startDate": "2024-03-25T12:00:00",
            "endDate": "2024-03-25T13:00:00"
        },
        "mug": {
            "startDate": "2024-03-25T13:00:00",
            "endDate": "2024-03-25T14:00:00"
        },
        "pap": {
            "startDate": "2024-03-25T14:00:00",
            "endDate": "2024-03-25T15:00:00"
        },
        "top": {
            "startDate": "2024-03-25T15:00:00",
            "endDate": "2024-03-25T16:00:00"
        },
        "hut": {
            "startDate": "2024-03-25T16:00:00",
            "endDate": "2024-03-25T17:00:00"
        }
    },
    "2024-03-26": {
        "wol": {
            "startDate": "2024-03-26T08:00:00",
            "endDate": "2024-03-26T09:00:00"
        },
        "pan": {
            "startDate": "2024-03-26T09:00:00",
            "endDate": "2024-03-26T10:00:00"
        },
        "aap": {
            "startDate": "2024-03-26T10:00:00",
            "endDate": "2024-03-26T11:00:00"
        },
        "hok": {
            "startDate": "2024-03-26T11:00:00",
            "endDate": "2024-03-26T12:00:00"
        },
        "zon": {
            "startDate": "2024-03-26T12:00:00",
            "endDate": "2024-03-26T13:00:00"
        },
        "bed": {
            "startDate": "2024-03-26T13:00:00",
            "endDate": "2024-03-26T14:00:00"
        },
        "zee": {
            "startDate": "2024-03-26T14:00:00",
            "endDate": "2024-03-26T15:00:00"
        },
        "fit": {
            "startDate": "2024-03-26T15:00:00",
            "endDate": "2024-03-26T16:00:00"
        },
        "bel": {
            "startDate": "2024-03-26T16:00:00",
            "endDate": "2024-03-26T17:00:00"
        }
    },
    "2024-03-27": {
        "kok": {
            "startDate": "2024-03-27T08:00:00",
            "endDate": "2024-03-27T09:00:00"
        },
        "kei": {
            "startDate": "2024-03-27T09:00:00",
            "endDate": "2024-03-27T10:00:00"
        },
        "gat": {
            "startDate": "2024-03-27T10:00:00",
            "endDate": "2024-03-27T11:00:00"
        },
        "jas": {
            "startDate": "2024-03-27T11:00:00",
            "endDate": "2024-03-27T12:00:00"
        },
        "oor": {
            "startDate": "2024-03-27T12:00:00",
            "endDate": "2024-03-27T13:00:00"
        },
        "gul": {
            "startDate": "2024-03-27T13:00:00",
            "endDate": "2024-03-27T14:00:00"
        },
        "wit": {
            "startDate": "2024-03-27T14:00:00",
            "endDate": "2024-03-27T15:00:00"
        },
        "rok": {
            "startDate": "2024-03-27T15:00:00",
            "endDate": "2024-03-27T16:00:00"
        },
        "hip": {
            "startDate": "2024-03-27T16:00:00",
            "endDate": "2024-03-27T17:00:00"
        }
    },
    "2024-03-28": {
        "gps": {
            "startDate": "2024-03-28T08:00:00",
            "endDate": "2024-03-28T09:00:00"
        },
        "kat": {
            "startDate": "2024-03-28T09:00:00",
            "endDate": "2024-03-28T10:00:00"
        },
        "mol": {
            "startDate": "2024-03-28T10:00:00",
            "endDate": "2024-03-28T11:00:00"
        },
        "uil": {
            "startDate": "2024-03-28T11:00:00",
            "endDate": "2024-03-28T12:00:00"
        },
        "zoo": {
            "startDate": "2024-03-28T12:00:00",
            "endDate": "2024-03-28T13:00:00"
        },
        "oom": {
            "startDate": "2024-03-28T13:00:00",
            "endDate": "2024-03-28T14:00:00"
        },
        "oma": {
            "startDate": "2024-03-28T14:00:00",
            "endDate": "2024-03-28T15:00:00"
        },
        "kar": {
            "startDate": "2024-03-28T15:00:00",
            "endDate": "2024-03-28T16:00:00"
        },
        "sla": {
            "startDate": "2024-03-28T16:00:00",
            "endDate": "2024-03-28T17:00:00"
        }
    },
    "2024-03-29": {
        "ree": {
            "startDate": "2024-03-29T08:00:00",
            "endDate": "2024-03-29T09:00:00"
        },
        "rug": {
            "startDate": "2024-03-29T09:00:00",
            "endDate": "2024-03-29T10:00:00"
        },
        "zak": {
            "startDate": "2024-03-29T10:00:00",
            "endDate": "2024-03-29T11:00:00"
        },
        "sap": {
            "startDate": "2024-03-29T11:00:00",
            "endDate": "2024-03-29T12:00:00"
        },
        "bos": {
            "startDate": "2024-03-29T12:00:00",
            "endDate": "2024-03-29T13:00:00"
        },
        "gek": {
            "startDate": "2024-03-29T13:00:00",
            "endDate": "2024-03-29T14:00:00"
        },
        "eik": {
            "startDate": "2024-03-29T14:00:00",
            "endDate": "2024-03-29T15:00:00"
        },
        "zus": {
            "startDate": "2024-03-29T15:00:00",
            "endDate": "2024-03-29T16:00:00"
        },
        "nek": {
            "startDate": "2024-03-29T16:00:00",
            "endDate": "2024-03-29T17:00:00"
        }
    },
    "2024-04-15": {
        "bus": {
            "startDate": "2024-04-15T08:00:00",
            "endDate": "2024-04-15T09:00:00"
        },
        "vla": {
            "startDate": "2024-04-15T09:00:00",
            "endDate": "2024-04-15T10:00:00"
        },
        "tak": {
            "startDate": "2024-04-15T10:00:00",
            "endDate": "2024-04-15T11:00:00"
        },
        "vos": {
            "startDate": "2024-04-15T11:00:00",
            "endDate": "2024-04-15T12:00:00"
        },
        "kip": {
            "startDate": "2024-04-15T12:00:00",
            "endDate": "2024-04-15T13:00:00"
        },
        "ufo": {
            "startDate": "2024-04-15T13:00:00",
            "endDate": "2024-04-15T14:00:00"
        },
        "mol": {
            "startDate": "2024-04-15T14:00:00",
            "endDate": "2024-04-15T15:00:00"
        },
        "kok": {
            "startDate": "2024-04-15T15:00:00",
            "endDate": "2024-04-15T16:00:00"
        },
        "oma": {
            "startDate": "2024-04-15T16:00:00",
            "endDate": "2024-04-15T17:00:00"
        }
    },
    "2024-04-16": {
        "gsm": {
            "startDate": "2024-04-16T08:00:00",
            "endDate": "2024-04-16T09:00:00"
        },
        "fit": {
            "startDate": "2024-04-16T09:00:00",
            "endDate": "2024-04-16T10:00:00"
        },
        "koe": {
            "startDate": "2024-04-16T10:00:00",
            "endDate": "2024-04-16T11:00:00"
        },
        "fee": {
            "startDate": "2024-04-16T11:00:00",
            "endDate": "2024-04-16T12:00:00"
        },
        "rok": {
            "startDate": "2024-04-16T12:00:00",
            "endDate": "2024-04-16T13:00:00"
        },
        "pap": {
            "startDate": "2024-04-16T13:00:00",
            "endDate": "2024-04-16T14:00:00"
        },
        "pan": {
            "startDate": "2024-04-16T14:00:00",
            "endDate": "2024-04-16T15:00:00"
        },
        "bed": {
            "startDate": "2024-04-16T15:00:00",
            "endDate": "2024-04-16T16:00:00"
        },
        "oor": {
            "startDate": "2024-04-16T16:00:00",
            "endDate": "2024-04-16T17:00:00"
        }
    },
    "2024-04-17": {
        "bus": {
            "startDate": "2024-04-17T08:00:00",
            "endDate": "2024-04-17T09:00:00"
        },
        "hok": {
            "startDate": "2024-04-17T09:00:00",
            "endDate": "2024-04-17T10:00:00"
        },
        "juf": {
            "startDate": "2024-04-17T10:00:00",
            "endDate": "2024-04-17T11:00:00"
        },
        "sms": {
            "startDate": "2024-04-17T11:00:00",
            "endDate": "2024-04-17T12:00:00"
        },
        "gek": {
            "startDate": "2024-04-17T12:00:00",
            "endDate": "2024-04-17T13:00:00"
        },
        "oog": {
            "startDate": "2024-04-17T13:00:00",
            "endDate": "2024-04-17T14:00:00"
        },
        "pop": {
            "startDate": "2024-04-17T14:00:00",
            "endDate": "2024-04-17T15:00:00"
        },
        "kat": {
            "startDate": "2024-04-17T15:00:00",
            "endDate": "2024-04-17T16:00:00"
        },
        "jas": {
            "startDate": "2024-04-17T16:00:00",
            "endDate": "2024-04-17T17:00:00"
        }
    },
    "2024-04-18": {
        "ijs": {
            "startDate": "2024-04-18T08:00:00",
            "endDate": "2024-04-18T09:00:00"
        },
        "hut": {
            "startDate": "2024-04-18T09:00:00",
            "endDate": "2024-04-18T10:00:00"
        },
        "gps": {
            "startDate": "2024-04-18T10:00:00",
            "endDate": "2024-04-18T11:00:00"
        },
        "kar": {
            "startDate": "2024-04-18T11:00:00",
            "endDate": "2024-04-18T12:00:00"
        },
        "bos": {
            "startDate": "2024-04-18T12:00:00",
            "endDate": "2024-04-18T13:00:00"
        },
        "pen": {
            "startDate": "2024-04-18T13:00:00",
            "endDate": "2024-04-18T14:00:00"
        },
        "wit": {
            "startDate": "2024-04-18T14:00:00",
            "endDate": "2024-04-18T15:00:00"
        },
        "eik": {
            "startDate": "2024-04-18T15:00:00",
            "endDate": "2024-04-18T16:00:00"
        },
        "bal": {
            "startDate": "2024-04-18T16:00:00",
            "endDate": "2024-04-18T17:00:00"
        }
    },
    "2024-04-19": {
        "mop": {
            "startDate": "2024-04-19T08:00:00",
            "endDate": "2024-04-19T09:00:00"
        },
        "bel": {
            "startDate": "2024-04-19T09:00:00",
            "endDate": "2024-04-19T10:00:00"
        },
        "zoo": {
            "startDate": "2024-04-19T10:00:00",
            "endDate": "2024-04-19T11:00:00"
        },
        "zak": {
            "startDate": "2024-04-19T11:00:00",
            "endDate": "2024-04-19T12:00:00"
        },
        "kip": {
            "startDate": "2024-04-19T12:00:00",
            "endDate": "2024-04-19T13:00:00"
        },
        "mos": {
            "startDate": "2024-04-19T13:00:00",
            "endDate": "2024-04-19T14:00:00"
        },
        "vla": {
            "startDate": "2024-04-19T14:00:00",
            "endDate": "2024-04-19T15:00:00"
        },
        "vlo": {
            "startDate": "2024-04-19T15:00:00",
            "endDate": "2024-04-19T16:00:00"
        },
        "uur": {
            "startDate": "2024-04-19T16:00:00",
            "endDate": "2024-04-19T17:00:00"
        }
    },
    "2024-04-22": {
        "pop": {
            "startDate": "2024-04-22T08:00:00",
            "endDate": "2024-04-22T09:00:00"
        },
        "uur": {
            "startDate": "2024-04-22T09:00:00",
            "endDate": "2024-04-22T10:00:00"
        },
        "zin": {
            "startDate": "2024-04-22T10:00:00",
            "endDate": "2024-04-22T11:00:00"
        },
        "pen": {
            "startDate": "2024-04-22T11:00:00",
            "endDate": "2024-04-22T12:00:00"
        },
        "vlo": {
            "startDate": "2024-04-22T12:00:00",
            "endDate": "2024-04-22T13:00:00"
        },
        "bal": {
            "startDate": "2024-04-22T13:00:00",
            "endDate": "2024-04-22T14:00:00"
        },
        "mug": {
            "startDate": "2024-04-22T14:00:00",
            "endDate": "2024-04-22T15:00:00"
        },
        "pap": {
            "startDate": "2024-04-22T15:00:00",
            "endDate": "2024-04-22T16:00:00"
        },
        "top": {
            "startDate": "2024-04-22T16:00:00",
            "endDate": "2024-04-22T17:00:00"
        }
    },
    "2024-04-23": {
        "wol": {
            "startDate": "2024-04-23T08:00:00",
            "endDate": "2024-04-23T09:00:00"
        },
        "gat": {
            "startDate": "2024-04-23T09:00:00",
            "endDate": "2024-04-23T10:00:00"
        },
        "gul": {
            "startDate": "2024-04-23T10:00:00",
            "endDate": "2024-04-23T11:00:00"
        },
        "ufo": {
            "startDate": "2024-04-23T11:00:00",
            "endDate": "2024-04-23T12:00:00"
        },
        "kei": {
            "startDate": "2024-04-23T12:00:00",
            "endDate": "2024-04-23T13:00:00"
        },
        "dak": {
            "startDate": "2024-04-23T13:00:00",
            "endDate": "2024-04-23T14:00:00"
        },
        "nek": {
            "startDate": "2024-04-23T14:00:00",
            "endDate": "2024-04-23T15:00:00"
        },
        "zin": {
            "startDate": "2024-04-23T15:00:00",
            "endDate": "2024-04-23T16:00:00"
        },
        "sla": {
            "startDate": "2024-04-23T16:00:00",
            "endDate": "2024-04-23T17:00:00"
        }
    },
    "2024-04-24": {
        "gom": {
            "startDate": "2024-04-24T08:00:00",
            "endDate": "2024-04-24T09:00:00"
        },
        "dag": {
            "startDate": "2024-04-24T09:00:00",
            "endDate": "2024-04-24T10:00:00"
        },
        "uil": {
            "startDate": "2024-04-24T10:00:00",
            "endDate": "2024-04-24T11:00:00"
        },
        "fan": {
            "startDate": "2024-04-24T11:00:00",
            "endDate": "2024-04-24T12:00:00"
        },
        "zon": {
            "startDate": "2024-04-24T12:00:00",
            "endDate": "2024-04-24T13:00:00"
        },
        "ree": {
            "startDate": "2024-04-24T13:00:00",
            "endDate": "2024-04-24T14:00:00"
        },
        "mug": {
            "startDate": "2024-04-24T14:00:00",
            "endDate": "2024-04-24T15:00:00"
        },
        "sap": {
            "startDate": "2024-04-24T15:00:00",
            "endDate": "2024-04-24T16:00:00"
        },
        "tak": {
            "startDate": "2024-04-24T16:00:00",
            "endDate": "2024-04-24T17:00:00"
        }
    },
    "2024-04-25": {
        "hip": {
            "startDate": "2024-04-25T08:00:00",
            "endDate": "2024-04-25T09:00:00"
        },
        "ton": {
            "startDate": "2024-04-25T09:00:00",
            "endDate": "2024-04-25T10:00:00"
        },
        "gsm": {
            "startDate": "2024-04-25T10:00:00",
            "endDate": "2024-04-25T11:00:00"
        },
        "gom": {
            "startDate": "2024-04-25T11:00:00",
            "endDate": "2024-04-25T12:00:00"
        },
        "lol": {
            "startDate": "2024-04-25T12:00:00",
            "endDate": "2024-04-25T13:00:00"
        },
        "mop": {
            "startDate": "2024-04-25T13:00:00",
            "endDate": "2024-04-25T14:00:00"
        },
        "dag": {
            "startDate": "2024-04-25T14:00:00",
            "endDate": "2024-04-25T15:00:00"
        },
        "opa": {
            "startDate": "2024-04-25T15:00:00",
            "endDate": "2024-04-25T16:00:00"
        },
        "mos": {
            "startDate": "2024-04-25T16:00:00",
            "endDate": "2024-04-25T17:00:00"
        }
    },
});
